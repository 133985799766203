import {
    Directive,
    ElementRef,
    inject,
    Input,
    OnInit,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { DemoWidgetComponent } from '../components/assistant-chat/components/chat-widgets/demo-widget/demo-widget.component';
import { InternalRouterWidgetComponent } from '../components/assistant-chat/components/chat-widgets/internal-router-widget/internal-router-widget.component';
import { TextSuggestionWidgetComponent } from '../components/assistant-chat/components/chat-widgets/text-suggestion-widget/text-suggestion-widget.component';
import { ComponentType } from 'ngx-toastr';

@Directive({
    selector: '[appChatWidgets]',
})
export class ChatWidgetsDirective implements OnInit {
    private readonly el = inject(ElementRef);
    private readonly renderer = inject(Renderer2);
    private readonly viewContainerRef = inject(ViewContainerRef);

    @Input() innerHTML!: string;

    ngOnInit() {
        this.detectAndReplaceWidgets();
    }

    /**
     * Detectar los widgets en el contenido y reemplazarlos por los componentes
     */
    detectAndReplaceWidgets() {
        const element = this.el.nativeElement;

        // Reemplazar <meta> por <span> antes de asignarlo a innerHTML
        //   const processedHTML = this.innerHTML.replace(
        //       /<meta\s+widget="true"\s+data='([^']+)'[^>]*>/g,
        //       (_, data) => `<span data-widget="true" data='${data}'></span>`
        //   );

        //   console.log('Processed HTML:', processedHTML);

        // Asignar el HTML modificado al elemento
        this.renderer.setProperty(element, 'innerHTML', this.innerHTML);

        // Buscar todos los widgets con data-widget="true"
        const widgets = element.querySelectorAll('[widget="true"]');
        if (!widgets.length) return;

        widgets.forEach((widgetElement: HTMLElement) => {
            const widgetData = widgetElement.getAttribute('data');
            if (!widgetData) return;

            // Parsear el JSON correctamente
            const parsedData = JSON.parse(widgetData);
            let componentRef = null;
            console.log('parsedData:', parsedData);

            // Seleccionar el componente basado en el tipo de widget
            if (parsedData.widget === 'internalRouter') {
                componentRef = this.createComponent(
                    InternalRouterWidgetComponent
                );
                componentRef.setInput('href', parsedData.href);
                componentRef.setInput('text', parsedData.text);
            } else if (parsedData.widget === 'text-suggestion') {
                componentRef = this.createComponent(
                    TextSuggestionWidgetComponent
                );
                componentRef.instance.text = parsedData.data.content;
            }

            // Reemplazar el marcador con el componente generado
            if (componentRef) {
                componentRef.changeDetectorRef.detectChanges();
                widgetElement.parentNode?.replaceChild(
                    componentRef.location.nativeElement,
                    widgetElement
                );
            }
        });
    }

    /** Crear componentes */
    createComponent(component: ComponentType<any>) {
        const componentRef = this.viewContainerRef.createComponent(component);
        return componentRef;
    }
}
