<div>
  <div class="flex items-center justify-between bg-slate-50 border-b border-slate-200 px-4 sm:px-6 md:px-5 h-16">

    <!-- Close button -->
    <button *ngIf="!chatService.componentStates.threadsMenu"
      (click)="chatService.componentStates.threadsMenu = !chatService.componentStates.threadsMenu"
      class="w-8 h-8 min-w-[2rem] min-h-[2rem] mr-2 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm"
      ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Mostrar Conversaciones'">
      <i class="fa-duotone fa-arrow-right-from-line opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
    </button>

    <img class="h-12 rounded-full mr-2" src="/assets/images/amparo-logo.webp" height="32" alt="Amparo IA"
      *ngIf="showLogo" />

    <p class="min-w-0 max-w-full font-semibold text-slate-800 inline-block truncate mr-auto" ngxTippy
      [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="threadName">
      {{ threadName }}
    </p>

    <!-- Buttons on the right side -->
    <div class="flex">

      <button (click)="chatService.deactivateThread()" *ngIf="chatService.componentStates.editor"
        class="relative grid size-8 shrink-0 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm group mr-4"
        [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Nueva Conversación'">
        <i class="fa-duotone fa-plus opacity-75 group-hover:opacity-100" style="font-size: 1rem"></i>
      </button>

      <!-- Thread docs -->
      <button id="assistant-document-list"
        *ngIf="chatService.loadingStates?.deletingDocuments?.value || chatService.loadingStates?.addingDocuments?.value || chatService.documents[0] || tutorialService.isTutorialActive('assistant-tour')"
        [menuTriggerFor]="threadDocs" [tippyProps]="{ placement: 'top', hideOnClick: true }"
        [ngxTippy]="docsProcessing() ? null : 'Contexto de la Conversación'"
        class="relative grid size-8 shrink-0 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm">
        <i *ngIf="!chatService.loadingStates?.deletingDocuments?.value && !chatService.loadingStates?.addingDocuments?.value"
          class="fa-duotone fa-file-lines opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
        <i *ngIf="chatService.loadingStates?.deletingDocuments?.value || chatService.loadingStates?.addingDocuments?.value "
          class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin" style="font-size: 1rem"></i>
        <div
          *ngIf="!chatService.loadingStates?.deletingDocuments?.value && !chatService.loadingStates?.addingDocuments?.value && !docsProcessing()"
          class="absolute grid place-content-center size-5 text-[0.6rem] leading-5 font-medium text-white bg-indigo-500 border-2 border-white rounded-full -top-2 -right-2">
          {{ chatService.documents.length }}
        </div>
        <div ngxTippy="Amparo IA está procesando el contexto de la conversación."
          class="absolute grid place-content-center size-5 font-medium text-white bg-slate-500 border-2 border-white rounded-full -top-2 -right-2 animate-spin"
          *ngIf="!chatService.loadingStates?.deletingDocuments?.value && !chatService.loadingStates?.addingDocuments?.value && docsProcessing()">
          <i class="fa-solid fa-spinner-third " style="font-size: 0.6rem"></i>
        </div>
      </button>
      <ng-template #threadDocs>
        <ul
          *ngIf="!chatService.loadingStates?.deletingDocuments?.value && !chatService.loadingStates?.addingDocuments?.value"
          class="mt-1 bg-pearl-100 border border-slate-200 rounded shadow-lg min-w-44 py-1.5 animate__animated animate__fadeIn animate__faster max-h-72 overflow-y-auto"
          cdkMenu>
          <li class="flex w-full mt-1 mb-2 px-3 justify-between items-center border-b border-slate-200 pb-1"
            *ngIf="chatService.canAddFiles()">
            <span class="text-sm">Contexto Seleccionado</span>
            <button *appValidateAccess="'asistente:documentos'" ngxTippy="Añadir Documento"
              class="shrink-0 size-7 rounded grid place-content-center gap-1 text-slate-600 hover:text-slate-700 hover:bg-slate-300/60 hover:shadow-sm"
              (click)="viewDocs()"
              [disabled]="chatService.loadingStates.sendingMessage.value || chatService.loadingStates.activeRun.value">
              <i class="fa-regular fa-paperclip-vertical" style="font-size: 0.9rem;"></i>
            </button>
          </li>
          <li *ngFor="let doc of chatService.documents; let i = index;" cdkMenuItem class="max-w-full xs:max-w-96">
            <div class="app-menu-option flex justify-between">
              <button class="truncate mr-2" (click)="viewDoc(doc)" ngxTippy="Ver síntesis generada por Amparo IA"
                [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }">
                {{ (doc.heading || doc.name) | titlecase }}
              </button>
              <!-- Si el documento está en procesamiento, mostramos un loader -->
              <div *ngIf="doc.isProcessing" class="size-7 shrink-0 grid place-content-center ml-auto"
                ngxTippy="Amparo IA está generando la síntesis del documento o asunto">
                <i class="fa-solid fa-spinner-third animate-spin" style="font-size: 0.9rem;"></i>
              </div>
              <!-- Si no está en procesamiento, mostramos el botón de la lupa -->
              <button *ngIf="!doc.isProcessing" (click)="viewDoc(doc)"
                class="size-7 shrink-0 grid place-content-center ml-auto rounded text-slate-400 hover:text-slate-500 hover:bg-slate-300/60 hover:shadow-sm"
                [tippyProps]="{ placement: 'top', hideOnClick: true }" ngxTippy="Ver síntesis generada por Amparo IA">
                <i class="fa-solid fa-magnifying-glass" style="font-size: 0.9rem;"></i>
              </button>
              <!-- Eliminar de la conversación -->
              <button (click)="chatService.removeDoc(doc)"
                class="size-7 shrink-0 grid place-content-center rounded text-rose-400 hover:text-rose-500 hover:bg-rose-300/60 hover:shadow-sm"
                [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Eliminar de la Conversación'">
                <i class="fa-solid fa-xmark" style="font-size: 0.9rem"></i>
              </button>
            </div>
          </li>
        </ul>
      </ng-template>

      <!-- IA Model -->
      <button id="assistant-model-selector" [menuTriggerFor]="modelsList"
        class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm" ngxTippy
        [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Modelo de Inteligencia Artificial'">
        <i *ngIf="!chatService.loadingStates?.loadingModels?.value"
          class="fa-duotone fa-microchip-ai opacity-75 group-hover:opacity-100"></i>
        <i *ngIf="chatService.loadingStates?.loadingModels?.value"
          class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
      </button>
      <ng-template #modelsList>
        <ul *ngIf="!chatService.loadingStates?.loadingModels?.value"
          class="mt-1 bg-pearl-100 border border-slate-200 rounded shadow-lg min-w-44 animate__animated animate__fadeIn animate__faster max-h-80 overflow-y-auto"
          cdkMenu>
          <li *ngFor="let iModel of chatService.models; let i = index;" cdkMenuItem class="max-w-full xs:max-w-72">
            <button
              [disabled]="chatService.loadingStates.sendingMessage.value || chatService.loadingStates.activeRun.value"
              [ngClass]="chatService.model == iModel.model_id ? ' disabled:bg-slate-200 text-primary-100 bg-slate-100' : 'enabled:hover:text-primary-300 enabled:hover:bg-slate-50 text-slate-800 hover:bg-slate-100 disabled:bg-slate-100 disabled:text-opacity-75'"
              class="font-medium text-sm  flex items-start py-1 px-3 disabled:text-slate-700 w-full group flex-col min-w-0 disabled:cursor-not-allowed"
              (click)="chatService.setModel(iModel.model_id)" [ngxTippy]="iModel.tooltip_text">
              <div class="flex items-center w-full gap-2 justify-between">
                <span class="truncate text-left">
                  {{ iModel.display_name }}
                </span>
                <span class="size-7 shrink-0 grid place-content-center rounded mr-1.5">
                  <i class="fa-light fa-gauge-simple-low" style="font-size: 1rem;"
                    [ngClass]="iconByModel(iModel.speed)"></i>
                </span>
              </div>
              <div class="text-xs text-slate-600 text-left">
                {{ iModel.description }}
              </div>
            </button>
          </li>
        </ul>
      </ng-template>

      <!-- Minimize button -->
      <button
        *ngIf="chatService.componentStates.maximize && chatService.componentStates.canClose && !chatService.componentStates.editor"
        (click)="chatService.minimize()"
        class="hidden sm:grid size-8 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm" ngxTippy
        [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Minimizar'">
        <i class="fa-duotone fa-arrows-minimize opacity-75 group-hover:opacity-100" style="font-size: 1rem"></i>
      </button>

      <!-- Maximize button -->
      <button
        *ngIf="!chatService.componentStates.maximize && chatService.componentStates.canClose && !chatService.componentStates.editor"
        (click)="chatService.maximize()"
        class="hidden group sm:grid size-8 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm" ngxTippy
        [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Maximizar'">
        <i class="fa-duotone fa-arrows-maximize opacity-75 group-hover:opacity-100" style="font-size: 1rem"></i>
      </button>

      <!-- botón de ayuda -->
      <button *ngIf="!chatService.componentStates.editor" id="assistant-tour-button" (click)="startTour()"
        class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
        [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Ver Ayuda'">
        <i class="fa-duotone fa-question-circle opacity-75 group-hover:opacity-100" style="font-size: 1rem"></i>
      </button>

      <!-- Close button -->
      <button *ngIf="chatService.editorModeBlob || chatService.componentStates.canClose" (click)="onCloseClick()"
        class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
        [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Cerrar'">
        <i class="fa-duotone fa-xmark opacity-75 group-hover:opacity-100" style="font-size: 1rem"></i>
      </button>

    </div>
  </div>
</div>