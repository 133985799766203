    <!-- Sidebar backdrop (mobile only) -->
    <div class="uisr-backdrop lg:hidden z-[99]"
        [ngClass]="sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'" aria-hidden="true"
        (click)="openOrCloseSidebar()"></div>

    <!-- Sidebar -->
    <div [ngClass]="sidebarOpen ? 'translate-x-0' : '-translate-x-64'" id="sidebar"
        class="flex flex-col absolute max-lg:h-[100svh] z-[100] left-0 top-0 lg:static lg:translate-x-0 overflow-y-scroll lg:overflow-y-auto no-scrollbar sidebar-expanded:w-64 w-20 shrink-0 bg-[#212832] dark:bg-black-pearl px-4 pb-4 transition-all duration-200 ease-in-out">

    <!-- Sidebar header -->
    <div class="flex justify-center mb-5 items-center lg:sidebar-expanded:px-4 sidebar-expanded:pt-2 h-16">
        <!-- Close button -->
        <div class="absolute bottom-4 right-4 lg:hidden">
            <button class="text-slate-500 hover:text-slate-400 p-2 rounded-full"
                aria-controls="sidebar" (click)="openOrCloseSidebar()">
                <span class="sr-only">Cerrar menú</span>
                <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                </svg>
            </button>
        </div>
        <!-- Logo -->
        <div *ngIf="!(loadingData| async) && !userData?.idWorkspace">
            <a *ngIf="sidebarExpanded" class="block" [routerLink]="'/main'">
                <img class="h-8 min-h-8 xs:h-10 xs:min-h-10 sm:h-12 sm:min-h-12 lg:min-w-[10rem]"
                    src="/assets/images/app-logo-white.png" alt="Logo MiDespacho" />
            </a>
            <a *ngIf="!sidebarExpanded" class="block" [routerLink]="'/main'">
                <img class="h-10 min-h-10" src="/assets/images/isotipo.png" alt="Logo MiDespacho" />
            </a>
        </div>
        <!-- Custom Logo -->
        <div *ngIf="!(loadingData| async) && userData?.idWorkspace" [routerLink]="'/main'"
            class="flex items-center gap-3 flex-none justify-start w-full cursor-pointer mt-5">
            <img class="size-11 rounded-full" [src]="(oldProfilePic ? oldProfilePic : '/assets/images/isotipo.png')"
                alt="Foto de Perfil" />
            <span [ngClass]="{'hidden': !sidebarExpanded}" class="max-w-[10rem] font-bold text-white"
                style="word-wrap: break-word; overflow-wrap: break-word;">{{workspace?.nameWorkSpace}}</span>
        </div>
        <div *ngIf="(loadingData| async) && sidebarExpanded"
            class="flex items-center gap-3 flex-none justify-start w-full cursor-pointer mt-5">
            <div class="rounded-full size-11 bg-gray-400 animate-pulse"></div>
            <div class="space-y-1 w-7/12">
                <div class="w-12/12 h-4 rounded-md bg-gray-400 animate-pulse"></div>
                <div class="w-12/12 h-4 rounded-md bg-gray-400 animate-pulse"></div>
            </div>
        </div>
    </div>
    <!-- Menu Items -->
    <div class="space-y-3" [ngClass]="workspace?.nameWorkSpace!.length > 24 ? 'mt-5' : 'mt-0'">
        <div class="mt-3">
            <!-- Iteración sobre los valores de menuItems -->
            <ng-container *ngFor="let menuItem of menuItems; let i = index; trackBy: trackByFn">
                <!-- Validar si menuItem es un objeto y está activo -->
                <ng-container
                    *ngIf="menuItem.active && (userData?.idWorkspace || userData?.id_workspace_member || menuItem.public)">
                    <app-menu-item [menuItem]="menuItem" [sidebarExpanded]="sidebarExpanded || false"
                        (sidebarExpandRequest)="$event ? changeSidebarStatus() : null">
                    </app-menu-item>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <!-- Expand / collapse button -->
    <div class="mt-auto w-full">
        <div class="px-3 py-2 max-lg:flex hidden">
            <a [routerLink]="'/law-firm/settings'" [ngxTippy]="'Configuración del despacho'"
                *ngIf="userData?.idWorkspace" (click)="trackSettingsClick()">
                <i class="fa-duotone fa-gear sidebar-expanded:rotate-180"
                    style="font-size: 1.3rem; --fa-primary-color: #94a3b8; --fa-secondary-color: #475569;"></i>
            </a>
        </div>
        <div
            class="pt-3 flex-col sidebar-expanded:justify-between items-center sidebar-expanded:flex-row hidden lg:flex">
            <div class="px-3 py-2">
                <a [routerLink]="'/law-firm/settings'" [ngxTippy]="'Configuración del despacho'"
                    *ngIf="userData?.idWorkspace" (click)="trackSettingsClick()">
                    <i class="fa-duotone fa-gear sidebar-expanded:rotate-180"
                        style="font-size: 1.3rem; --fa-primary-color: #94a3b8; --fa-secondary-color: #475569;"></i>
                </a>
            </div>
            <div class="px-3 py-2">
                <button (click)="changeSidebarStatus()">
                    <span class="sr-only">Expand / collapse sidebar</span>
                    <i class="fa-duotone fa-arrow-right-from-line sidebar-expanded:rotate-180"
                        style="font-size: 1.5rem; --fa-primary-color: #94a3b8; --fa-secondary-color: #475569;"></i>
                </button>
            </div>
        </div>
    </div>

</div>
