import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AssistantChatService } from '../../services/assistant-chat.service';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ViewDocComponent } from '../view-doc/view-doc.component';
import { SimpleDocPickerComponent } from '../../../simple-doc-picker/simple-doc-picker.component';
import { ALLOWED_AMPARO_EXTS } from '../../amparo-ext.constants';
import { environment } from 'src/environments/environment';
import { ASSISTANT_STEPS } from 'src/app/core/constants/assistant-steps.steps';

@Component({
    selector: 'app-chat-thread-header',
    templateUrl: './chat-thread-header.component.html',
    host: {
        class: 'contents',
    },
})
export class ChatThreadHeaderComponent {
    private readonly dialog = inject(DialogService);
    readonly chatService = inject(AssistantChatService);
    readonly tutorialService = inject(AppTutorialService);

    steps = ASSISTANT_STEPS;

    @Input() threadName = '';
    @Input() showLogo = false;

    @Output() closeClick = new EventEmitter();

    docsProcessing() {
        return this.chatService.documents?.some((d) => d.isProcessing);
    }

    /** Visualizar un modal con la información de un documento procesado (resumen, keywords...) */
    viewDoc(doc: any) {
        this.dialog.openDialog(ViewDocComponent, { data: { doc } });
    }

    /** Abre el modal del selector de documentos simple y obtiene los ids de los archivos seleccionados*/
    viewDocs() {
        this.dialog
            .openDialog(SimpleDocPickerComponent, {
                data: {
                    prompt: 'Agrega contexto a tu conversación con Amparo IA. Selecciona asuntos, agrega documentos o jurisprudencia desde tus colecciones.',
                    extensions: ALLOWED_AMPARO_EXTS,
                    existing: this.chatService.documents,
                    sizeLimit: environment.amparoSizeLimit,
                },
            })
            .subscribe((selection: any) => {
                if (selection) {
                    this.chatService.addDocs(selection);
                }
            });
    }

    /** Realiza el llamado al servicio del tutorial para empezar el tour del asistente */
    startTour() {
        this.tutorialService.startTour(this.steps, undefined, 'assistant-tour');
    }

    onCloseClick() {
        this.closeClick.emit();
    }

    iconByModel(speed: string) {
        switch (speed) {
            case 'baja':
                return 'fa-light fa-gauge-low';
            case 'alta':
                return 'fa-light fa-gauge-max';
            case 'media':
                return 'fa-light fa-gauge-high';
            default:
                return 'fa-light fa-gauge-simple';
        }
    }
}
