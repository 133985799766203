<ng-container *ngFor="let storageObject of storageObjects; let i = index; trackBy:trackByFn">
  <!-- Carpeta o archivo -->
  <div class="-mt-px first:mt-0" [ngxTippy]="getTooBigTooltip(storageObject.file)" [tippyProps]="{ allowHTML: true}">
    <button type="button"
      class="enabled:hover:bg-indigo-50 flex items-center gap-2 p-2 group border w-full border-transparent"
      (click)="container.onStorageObjectClick(storageObject)"
      [disabled]="(container.loadingFolder | async) || docPickerService.isExisting(storageObject.file) || (storageObject.file && isTooBig(storageObject.file))"
      [ngClass]="{'bg-indigo-50 !border-indigo-600 !relative': storageObject.file && (docPickerService.isSelected(storageObject.file) || docPickerService.isExisting(storageObject.file)), 'opacity-50': storageObject.file && isTooBig(storageObject.file)}">
      <ng-container *ngIf="!(container.loadingFolder | async) || container.selectedFolder?.id != storageObject.id">
        <i class="fa-regular text-[1rem] text-slate-600" [ngClass]="storageObject.file.extension | extToIcon"
          *ngIf="storageObject.file"></i>
        <i class="fa-regular text-[1rem] text-slate-600 fa-folder" *ngIf="!storageObject.file"></i>
      </ng-container>
      <ng-container *ngIf="(container.loadingFolder | async) && container.selectedFolder?.id == storageObject.id">
        <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
      </ng-container>
      <span class="font-medium text-slate-600 truncate text-sm">{{ storageObject.name | titlecase }}</span>
      <div class="px-3 ml-auto" *ngIf="!storageObject.file">
        <div class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible">
          <i class="fa-solid fa-chevron-right text-sm"></i>
        </div>
      </div>
      <div class="px-3 ml-auto pointer-events-none flex items-center" *ngIf="storageObject.file">
        <div class="size-6 rounded-full grid place-content-center text-indigo-600 bg-indigo-100"
          [ngClass]="docPickerService.isSelected(storageObject.file) || docPickerService.isExisting(storageObject.file) ? 'visible': 'invisible'">
          <i class="fa-solid fa-check text-[0.9rem]"></i>
        </div>
        <div
          *ngIf="!docPickerService.isSelected(storageObject.file) && !docPickerService.isExisting(storageObject.file)"
          class="text-xs font-medium text-slate-500 text-nowrap">
          <i class="fa-regular fa-triangle-exclamation mr-1" *ngIf="isTooBig(storageObject.file)"></i>
          {{storageObject.file.size | fileSize}}
        </div>
      </div>
    </button>
  </div>

  <!-- Espacio para mostrar las sub-carpetas o documentos dentro una carpeta -->
  <div class="pl-5">
    <app-simple-storage-object-data [storageObjects]="storageObject.storageObjects"
      *ngIf="storageObject.storageObjects && storageObject.open" [parentId]="storageObject.id" />
  </div>
</ng-container>

<!-- Empty state si no hay carpetas o documentos dentro del asunto -->
<div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-2"
  *ngIf="!storageObjects[0] && (!isLoading() || parentId && container.selectedFolder?.id != parentId)">
  <div class="bg-indigo-100 size-8 grid place-content-center rounded-full">
    <i class="fa-solid fa-folder-open text-[0.8rem]"></i>
  </div>
  <span class="text-center font-medium text-sm">No encontramos documentos en esta ubicación</span>
</div>