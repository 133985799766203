import { inject, Injectable } from '@angular/core';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { ClientFolder } from 'src/app/features/law-firm/interfaces/client.interfaces';
import { DossierDTO } from 'src/app/features/law-firm/interfaces/dossier.interfaces';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { UisrApiServiceV2 } from './uisr-api.service-v2';

/** Los posibles destinos hacia los cuales se pueden copiar archivos */
export enum FileCopyTargetType {
    Studio = 'studio',
    Dossiers = 'dossier',
    Clients = 'client',
    EmptyDossier = 'emptyDossier',
}

/** Los posibles orígenes desde los cuales se pueden copiar archivos */
export enum FileCopySourceType {
    Studio = 'studio',
    Dossiers = 'dossiers',
    Clients = 'client',
    Templates = 'templates',
}

@Injectable({
    providedIn: 'root',
})
export class StudioFileService {
    private readonly api = inject(UisrApiServiceV2);

    /** Solicita copiar un documento desde un origen (dossiers. templates, clients) a otra area */
    copyToAnotherArea(
        source_id: number,
        target: ClientFolder | StudioFiles | StorageObject | DossierDTO,
        source_type: FileCopySourceType,
        target_type: FileCopyTargetType,
        move: boolean = false
    ) {
        let target_id;
        let url = '';

        // Extraer el id del destino al que se va a copiar el documento, depende de la entidad a la cual se copia pero siempre se manda como target_id
        if ('studio_file_id' in target) {
            target_id = target.studio_file_id;
        } else if ('idClientDossier' in target) {
            target_id = target.idClientDossier;
        } else if ('id' in target) {
            target_id = target.id;
        } else if ('idDossier' in target) {
            target_id = target.idDossier;
        }

        // Dependiendo del destino, se debe enviar la solicitud a endpoints específicos
        if (target_type === 'studio') {
            url = RESOURCES.copyStudioFiles;
        } else if (
            target_type === 'dossier' ||
            target_type === 'emptyDossier'
        ) {
            url = RESOURCES.copyDossierFiles;
        } else if (target_type === 'client') {
            url = RESOURCES.copyClientFiles;
        }

        return this.api.patch(url, {
            source_id,
            target_id,
            source_type,
            target_type,
            move,
        });
    }

    /** Crea una url de la ubicación a la que se copió un archivo */
    getDestinationUrl(
        target: ClientFolder | StudioFiles | StorageObject | DossierDTO,
        target_type: FileCopyTargetType
    ) {
        let target_id;
        let url = '/law-firm/';

        // Extraer el id del destino al que se va a copiar el documento, depende de la entidad a la cual se copia pero siempre se manda como target_id
        if ('studio_file_id' in target) {
            target_id = target.studio_file_id;
        } else if ('idClientDossier' in target) {
            target_id = target.idClientDossier;
        } else if ('id' in target) {
            target_id = target.id;
        } else if ('idDossier' in target) {
            target_id = target.idDossier;
        }

        // Dependiendo del destino, se debe armar la url correspondiente
        if (target_type === 'studio') {
            url += `library/studio?folderId=${target_id}`;
        } else if (target_type === 'dossier') {
            url += `files/file-overview/${(target as StorageObject).dossierId}?action=documents&folderId=${target_id}`;
        } else if (target_type === 'emptyDossier') {
            url += `files/file-overview/${target_id}?action=documents`;
        } else if (target_type === 'client') {
            url += `clients/${target_id}?action=documents`;
        }

        return url;
    }
}
