import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    CustomToolbarItemModel,
    DocumentEditorContainerComponent,
    ToolbarItem,
} from '@syncfusion/ej2-angular-documenteditor';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { UserData } from 'src/app/core/models/user-data';
import { UisrAnalyticsService } from 'src/app/core/services/analytics.service';
import { ALERT_TOAST_DEFAULTS } from 'src/app/features/calendar/constants/alert-defaults.constants';
import { AssistantChatService } from 'src/app/shared/components/assistant-chat/services/assistant-chat.service';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@UntilDestroy()
@Component({
    selector: 'app-doc-editor',
    templateUrl: './doc-editor.component.html',
    host: {
        class: 'flex flex-col h-full w-full',
        '[class.bg-slate-100]': 'editorReady',
    },
})
export class DocEditorComponent {
    private readonly dialogData = inject(DIALOG_DATA, { optional: true });
    private readonly dialogRef = inject(DialogRef, { optional: true });
    private readonly assistantService = inject(AssistantChatService);

    @ViewChild('document_editor')
    public editorContainer!: DocumentEditorContainerComponent;

    @ViewChild('docEditorContainer')
    docEditorContainer?: ElementRef<any>;

    @Output()
    public onSaveDocxBlobDocument: any = new EventEmitter();

    @Output()
    public onSaveTxtBlobDocument: any = new EventEmitter();

    @Output()
    public onSaveSfdtBlobDocument: any = new EventEmitter();

    @Output()
    public onSavePdfBlobDocument: any = new EventEmitter();

    @Output()
    public onSaveFileDocument: any = new EventEmitter();

    @Output()
    public onCloseEditor: any = new EventEmitter();

    // Definir grupos de estados de carga
    loadingStates = {
        loadingDocument: new BehaviorSubject<boolean>(false), // Se esta cargando un documento
    };

    cacheThread = false;
    componentStates = {
        assistant: false,
    };
    currentContentDocx?: Blob;

    @HostListener('window:resize', []) onWindowResize() {
        this.updateDocumentEditorSize();
    }

    private readonly analyticsService = inject(UisrAnalyticsService);
    private readonly apiService = inject(UisrApiServiceV2);
    private editorReadySubject = new Subject<boolean>();
    editorReady$: Observable<boolean> = this.editorReadySubject.asObservable();
    public editorReady: boolean = false;
    public serviceLink: string = environment.apiDocumentEditorUrl;
    public docForm: FormGroup;
    public hasChanges: boolean = false;
    public initialHash!: number;
    public readOnly: boolean = false;
    private readonly EMPTY_DOCUMENT_HASH = -618494887;
    userData?: UserData;

    toolbarItemOpen: CustomToolbarItemModel = {
        prefixIcon: 'e-de-ctnr-open',
        tooltipText: 'Abrir',
        text: 'Abrir',
        id: 'OpenFile',
    };

    // Boton para guardar el documento
    toolbarItemSave: CustomToolbarItemModel = {
        prefixIcon: 'e-de-ctnr-upload',
        tooltipText: 'Guardar',
        text: 'Guardar',
        id: 'Save',
    };

    toolbarItemSaveAndExport: CustomToolbarItemModel = {
        prefixIcon: 'e-de-ctnr-download',
        tooltipText: 'Exportar',
        text: 'Exportar',
        id: 'SaveAndExport',
    };

    // Botones de la barra de herramientas
    toolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
        this.toolbarItemSave,
        this.toolbarItemSaveAndExport,
        'New',
        this.toolbarItemOpen,
        'Separator',
        'Undo',
        'Redo',
        'Separator',
        'Image',
        'Table',
        'Hyperlink',
        'Bookmark',
        'TableOfContents',
        'Separator',
        'Header',
        'Footer',
        'PageSetup',
        'PageNumber',
        'Break',
        'InsertFootnote',
        'InsertEndnote',
        'Separator',
        'Find',
        'Separator',
        'Comments',
        'TrackChanges',
        'Separator',
        'LocalClipboard',
        'RestrictEditing',
        'Separator',
        'FormFields',
        'UpdateFields',
    ];

    private readonly formBuilder = inject(FormBuilder);

    constructor() {
        // Inicializar formulario
        this.docForm = this.formBuilder.group({
            title: ['Nuevo Documento', [Validators.required]],
            documentId: [],
        });
    }

    /**
     * Al estar creado el editor
     */
    onEditorCreated(): void {
        this.editorReady = true;
        this.editorReadySubject.next(this.editorReady);
        this.initialHash = this.getContentHash();

        console.log('📌 Hash de documento vacío:', this.initialHash);
        if (this.readOnly || this.dialogData?.readOnly) {
            this.setReadOnly();
        }

        // Si el editor se abre desde un dialogo, debe tener un método para cargar la url pública del documento
        if (this.dialogData?.loadUrl) {
            this.dialogData
                .loadUrl(this.dialogData.documentId)
                .pipe(
                    loadingState(this.loadingStates.loadingDocument),
                    untilDestroyed(this)
                )
                .subscribe({
                    next: (res: string) => this.loadDocumentUrl(res, true),
                });
        }

        if (this.dialogData?.title || this.dialogData?.documentId) {
            this.docForm.patchValue({
                title: this.dialogData.title,
                documentId: this.dialogData.documentId,
            });
        }

        if (this.dialogData?.openAmparo && window.innerWidth > 1024) {
            this.toggleAmparoForDocs();
        }

        if (this.dialogData?.cacheThread) {
            this.cacheThread = this.dialogData.cacheThread;
        }

        this.assistantService.onContentEmitted.subscribe((content) => {
            this.insertIntoEditor(String(content));
            this.setCurrentContentDocx();
        });
    }

    insertIntoEditor(htmlContent: string) {
        // Llamar al endpoint para convertir HTML a SFDT
        this.apiService
            .post(RESOURCES.documentEditorSfdtFromHtml, { htmlContent })
            .pipe(
                loadingState(
                    this.assistantService.loadingStates.parsingHtmlToSfdt
                )
            )
            .subscribe({
                next: (response) =>
                    this.editorContainer.documentEditor.editor.paste(
                        response.data
                    ),
            });
    }

    /**
     * Establecer el editor en modo lectura
     */
    setReadOnly() {
        this.docForm.disable();
        this.editorContainer.documentEditor.isReadOnly = true;
        this.editorContainer.enableToolbar = false;
        this.editorContainer.showPropertiesPane = false;
    }

    /**
     * Interceptar solicitudes del editor
     */
    beforeXmlHttpRequestSend(event: any) {}

    /**
     * Cerrar el editor
     */
    beforeClosingEditor(): void {
        this.checkForChanges();

        if (this.hasChanges) {
            Swal.fire({
                ...ALERT_DEFAULTS,
                icon: 'warning',
                title: 'Tienes cambios sin guardar',
                html: 'Si abandonas esta página, perderás los cambios que aún no has guardado.<br>¿Estás seguro de que deseas continuar?',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Sí, abandonar sin guardar',
            }).then((res) => {
                if (res.isConfirmed) {
                    this.closeEditor();
                }
            });
        } else {
            this.closeEditor();
        }
    }

    closeEditor() {
        this.onCloseEditor.emit();
        if (this.dialogRef?.id) {
            this.dialogRef?.close();
        }
        this.assistantService.editorModeBlob = undefined;
        this.assistantService.restoreCachedThread();
    }

    /**
     * Click de los botones del toolbar
     */
    onToolbarClick(args: any): void {
        switch (args.item.id) {
            case 'Save':
                this.toolbarSaveFile();
                break;
            case 'SaveAndExport':
                this.exportFileAsDocx();
                break;
            case 'OpenFile':
                this.toolbarOpenFile();
                break;
        }
    }

    /**
     * Abrir documento word programaticamente
     */
    toolbarOpenFile(): void {
        // Crear un elemento de entrada tipo file
        const inputElement = document.createElement('input');
        inputElement.type = 'file';

        // Establecer que el input acepte solo archivos .docx
        inputElement.accept =
            '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        // Establecer un manejador de eventos para cuando se seleccione un archivo
        inputElement.onchange = (event: Event) => {
            const file = (event.target as HTMLInputElement).files?.[0];
            if (file) {
                this.loadDocument(file);
            }

            inputElement.remove();
        };

        // Agregar un manejador de eventos `oncancel` para detectar si el usuario cancela
        inputElement.onblur = () => {
            // Asegurarse de eliminar el input si se cancela la selección
            inputElement.remove();
        };

        // Simular un clic en el input para abrir el cuadro de selección de archivos
        inputElement.click();
    }

    /**
     * Cargar documento por url
     */
    loadDocumentUrl(url: string, initial: boolean = false): void {
        this.apiService
            .post(`${RESOURCES.documentEditorSfdtByUrl}`, {
                url: url,
            })
            .pipe(loadingState(this.loadingStates.loadingDocument))
            .subscribe((response: any) => {
                if (response.data.sfdt) {
                    this.editorContainer.documentEditor.open(
                        JSON.stringify(response.data)
                    );
                    if (initial) {
                        this.initialHash = this.getContentHash();
                    }

                    this.setCurrentContentDocx();
                }
            });
    }

    /**
     * Cargar documento
     */
    loadDocument(file: File, initial: boolean = false): void {
        let formData: FormData = new FormData();
        formData.append('files', file);

        this.apiService
            .post(`${RESOURCES.documentEditorSfdtByFile}`, formData)
            .pipe(loadingState(this.loadingStates.loadingDocument))
            .subscribe((response: any) => {
                if (response.data.sfdt) {
                    this.editorContainer.documentEditor.open(
                        JSON.stringify(response.data)
                    );
                    this.docForm.patchValue({
                        title: file.name.substring(
                            0,
                            file.name.lastIndexOf('.')
                        ),
                    });
                    if (initial) {
                        this.initialHash = this.getContentHash();
                    }
                }
            });
    }

    /**
     * Accion del boton de guardar
     */
    toolbarSaveFile() {
        this.docForm.updateValueAndValidity();
        if (this.docForm.valid) {
            if (this.dialogData?.saveFile) {
                this.confirmBeforeSave();
            } else {
                this.saveFileAsDocx();
            }
        }
    }

    confirmBeforeSave() {
        if (this.docForm.value.documentId) {
            Swal.fire({
                ...ALERT_DEFAULTS,
                icon: 'question',
                title: 'Actualizar Documento',
                html: '¿Estás seguro de que deseas actualizar el documento?',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Actualizar',
            }).then((res) => {
                if (res.isConfirmed) {
                    this.saveFileAsDocx();
                }
            });
        } else {
            this.saveFileAsDocx();
        }
    }

    /**
     * Exportar y descargar el archivo como .docx.
     */
    exportFileAsDocx(): void {
        this.editorContainer.documentEditor.saveAsBlob('Docx').then((blob) => {
            // Crear un enlace temporal para descargar el archivo
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.docForm.value.title}.docx`; // Nombre del archivo con título
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });

        if (
            this.userData?.id_users !== undefined &&
            this.userData?.idWorkspace !== undefined
        ) {
            this.analyticsService.trackDocEditorDocumentExported(
                this.userData.id_users,
                this.userData.idWorkspace,
                this.docForm.value.documentId
            );
        }
    }

    /**
     * Guardar como MSWord
     */
    async saveFileAsDocx(): Promise<void> {
        let blob = await this.editorContainer.documentEditor.saveAsBlob('Docx');
        this.onSaveDocxBlobDocument.emit(blob);
        if (this.dialogData?.saveFile) {
            this.dialogData
                .saveFile(blob, {
                    title: `${this.docForm.value.title}.docx`,
                    documentId: this.docForm.value.documentId,
                })
                .pipe(
                    loadingState(this.loadingStates.loadingDocument),
                    untilDestroyed(this)
                )
                .subscribe({
                    next: () => {
                        this.initialHash = this.getContentHash();
                        if (this.docForm.value.documentId) {
                            Swal.fire({
                                ...ALERT_TOAST_DEFAULTS,
                                icon: 'success',
                                text: 'Documento Actualizado',
                            });
                        } else {
                            Swal.fire({
                                ...ALERT_TOAST_DEFAULTS,
                                icon: 'success',
                                text: 'Documento agregado',
                            });
                        }
                    },
                });
        }
        this.onSaveFileDocument.emit(
            new File([blob], `${this.docForm.value.title}.docx`, {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
        );

        if (
            this.userData?.id_users !== undefined &&
            this.userData?.idWorkspace !== undefined
        ) {
            const isCreating = this.initialHash === this.EMPTY_DOCUMENT_HASH;
            if (isCreating) {
                this.analyticsService.trackDocEditorDocumentCreated(
                    this.userData.id_users,
                    this.userData.idWorkspace,
                    this.docForm.value.title
                );
            } else {
                this.analyticsService.trackDocEditorDocumentEdited(
                    this.userData.id_users,
                    this.userData.idWorkspace,
                    this.docForm.value.documentId
                );
            }
        }
    }

    /**
     * Guardar como Txt
     */
    async saveFileAsTxt(): Promise<void> {
        let blob = await this.editorContainer.documentEditor.saveAsBlob('Txt');
        this.onSaveTxtBlobDocument.emit(blob);
        this.onSaveFileDocument.emit(
            new File([blob], `${this.docForm.value.title}.txt`, {
                type: 'text/plain',
            })
        );
    }

    /**
     * Guardar como SyncFusionDocumentText
     */
    async saveFileAsSfdt(): Promise<void> {
        let blob = await this.editorContainer.documentEditor.saveAsBlob('Sfdt');
        this.onSaveSfdtBlobDocument.emit(blob);
        this.onSaveFileDocument.emit(
            new File([blob], `${this.docForm.value.title}.sfdt`, {
                type: 'application/json',
            })
        );
    }

    /**
     * Guardar como Pdf
     */
    /*async saveFileAsPdf(): void {
    let blob = await this.editorContainer.documentEditor.saveAsBlob('Pdf');
    this.onSavePdfBlobDocument.emit(blob);
    this.onSaveFileDocument.emit(new File([blob], `${this.docForm.value.title}.pdf`, {
      type: 'application/pdf'
    }));
  }*/

    /**
     * Al cambiar el contenido
     */
    async onContentChange(event: any) {
        if (
            this.userData?.id_users !== undefined &&
            this.userData?.idWorkspace !== undefined
        ) {
            this.analyticsService.trackDocEditorDocumentEdited(
                this.userData.id_users,
                this.userData.idWorkspace,
                this.docForm.value.documentId
            );
        }

        if (this.componentStates.assistant) {
            this.setCurrentContentDocx();
        }
    }

    async setCurrentContentDocx() {
        try {
            const blob = await this.editorContainer.documentEditor.saveAsBlob(
                'Docx'
            );
            this.currentContentDocx = blob;
        } catch (error) {
            console.error('Error saving content:', error);
        }
    }

    /**
     * Al cambiar el documento por completo
     */
    onDocumentChange(event: any) {}

    /**
     * Comprueba si hay cambios en el documento
     */
    checkForChanges() {
        this.hasChanges =
            this.initialHash == this.getContentHash() ? false : true;
    }

    /**
     * Obtiene hash del contenido actual del editor
     */
    getContentHash() {
        let content = JSON.parse(
            this.editorContainer.documentEditor.serialize()
        );
        let contentStr = JSON.stringify({
            sec: content.sec,
            sty: content.sty,
        });
        return this.stringToHash(contentStr);
    }

    /**
     * Obtiene hash de un string
     */
    stringToHash(string: string) {
        let hash = 0;
        if (string.length == 0) return hash;
        for (let i = 0; i < string.length; i++) {
            let char = string.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return hash;
    }

    async toggleAmparoForDocs() {
        this.componentStates.assistant = !this.componentStates.assistant;
        setTimeout(() => {
            this.updateDocumentEditorSize();
        }, 0);
    }

    updateDocumentEditorSize() {
        const width = this.docEditorContainer?.nativeElement.offsetWidth ?? 0;
        this.editorContainer.resize(width);
    }
}
