<app-indeterminate-loader
    *ngIf="assistantChatService.loadingStates.loadingThread.value || assistantChatService.loadingStates.loadingThreadMessages.value"
    [position]="'absolute'" [top]="'0'" [left]="'0'"></app-indeterminate-loader>

<div #messagesWrapper id="assistant-messages-wrapper"
    class="w-full max-h-full overflow-y-auto px-4 sm:px-6 md:px-5 py-6 grow"
    style="overflow-anchor: auto; scroll-behavior: smooth;" infiniteScroll [infiniteScrollUpDistance]="0"
    [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolledUp)="assistantChatService.fetchNextMessages()">
    <ng-container
        *ngIf="!assistantChatService.messages[0] && !assistantChatService.thread && !tutorialService.isTutorialActive('assistant-tour')">
        <!-- Info Text -->
        <div *ngIf="!assistantChatService.documents[0] && assistantChatService.showInfo"
            class="flex items-start mb-4 last:mb-0" style="overflow-anchor: none;">
            <img class="rounded-full mr-4 min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]"
                src="/assets/images/amparo-isotipo.webp" />
            <div>
                <div class="bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md">
                    <div class="prose text-sm" [innerHTML]="infoContent"></div>
                </div>
            </div>
        </div>
        <!-- Chips de acciones -->
        <div *ngIf="!assistantChatService.showInfo && assistantChatService.showChips"
            class="grid md:grid-cols-2 lg:grid-cols-4 gap-4 overflow-x-auto no-scroll grow max-w-[43.75rem] mx-auto"
            style="overflow-anchor: none;">
            <button *ngFor="let chip of (chips | async)" (click)="onChipSelection(chip)"
                class="lg:max-w-40 border border-slate-300 rounded p-3 flex flex-col gap-2 items-start hover:bg-slate-200 hover:text-slate-800 hover:border-slate-400 hover:shadow-sm max-sm:even:hidden group">
                <i [class]="chip.icon" class="text-[0.9rem] text-indigo-500"></i>
                <span class="text-sm text-slate-600 text-left font-medium">{{ chip.text }}</span>
            </button>
        </div>
    </ng-container>


    <!-- Msg loading -->
    <div *ngIf="assistantChatService.loadingStates.loadingThreadMessages.value"
        class="w-full h-full flex items-center justify-center text-slate-400 mb-4" style="overflow-anchor: none">
        <svg class="animate-spin w-8 h-8 fill-current shrink-0" viewBox="0 0 16 16">
            <path
                d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z">
            </path>
        </svg>
    </div>

    <!-- Chat msg -->
    <ng-container *ngIf="!assistantChatService.loadingStates.loadingThread.value">
        <app-dummy-chat *ngIf="tutorialService.isTutorialActive('assistant-tour')" />

        <ng-container *ngFor="let message of assistantChatService.messages; let i = index">

            <!-- Date separator -->
            <div *ngIf="isNewDayMessage(message.created_at, assistantChatService.messages[i - 1]?.created_at).condition"
                class="flex justify-center" style="overflow-anchor: none;">
                <div
                    class="inline-flex items-center justify-center text-xs text-slate-600 font-medium px-2.5 py-1 bg-white border border-slate-200 rounded-full mb-5">
                    {{ isNewDayMessage(message.created_at, assistantChatService.messages[i - 1]?.created_at).date }}
                </div>
            </div>

            <!-- Assistant -->
            <div *ngIf="message.role == 'assistant'" class="flex items-start mb-4 last:mb-0"
                style="overflow-anchor: none;">
                <img class="rounded-full mr-4 size-10 shrink-0" src="/assets/images/amparo-isotipo.webp" />
                <div class="group/message flex flex-col min-w-0">
                    <div
                        class="bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-sm mb-1 prose text-sm min-w-0 flex">
                        <ng-container *ngIf="message?.typedId">
                            <p class="m-0 inline-block w-0">&nbsp;</p>
                            <div class="prose text-sm m-0 inline-block"
                                [id]="(assistantChatService.editorModeBlob ? 'editor-' : '') + message?.typedId"></div>
                        </ng-container>
                        <div class="min-w-0" *ngIf="!message?.typedId" [innerHTML]="message.content" appChatWidgets>
                        </div>
                    </div>
                    <ng-container *ngIf="!message.typedId && assistantChatService.studioFileIdByRunId(message.run_id)">
                        <app-amparo-generated-file
                            [studio_file_id]="assistantChatService.studioFileIdByRunId(message.run_id)"
                            (copy_request)="assistantChatService.copyStudioFile($event)" />
                    </ng-container>
                    <div class="group/actions flex parent-div"
                        [ngClass]="assistantChatService.editorModeBlob ? 'visible': 'invisible group-hover/message:visible'">
                        <button *ngIf="!message?.typedId" ngxTippy="Copiar" (click)="shareMessage(message.content)"
                            class="group size-7 grid place-content-center rounded text-slate-500 hover:text-slate-700 opacity-80 group-hover:opacity-100">
                            <i class="fa-solid fa-copy" style="font-size: 0.9rem"></i>
                        </button>
                        <button *ngIf="assistantChatService.stepsByRunId(message.run_id)[0]"
                            [menuTriggerFor]="stepsList"
                            [menuPosition]="[{originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'}]"
                            class="group size-7 grid place-content-center rounded text-slate-500 hover:text-slate-700 opacity-80 group-hover:opacity-100"
                            ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }"
                            [ngxTippy]="'Proceso Ejecutado'">
                            <i class="fa-duotone fa-solid fa-gears opacity-80 group-hover:opacity-100"
                                style="font-size: 0.9rem"></i>
                        </button>
                        <ng-template #stepsList>
                            <div class="uisr-dropdown-menu max-w-96 my-3" cdkMenu>
                                <div class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
                                    <div class="font-medium text-slate-800">
                                        Proceso Ejecutado
                                    </div>
                                </div>
                                <div class="prose px-3 p-1">
                                    <ol class="text-xs" *ngIf="assistantChatService.stepsByRunId(message.run_id)[0]">
                                        <ng-container
                                            *ngFor="let step of assistantChatService.stepsByRunId(message.run_id)">
                                            <li *ngIf="step.display_message" class="m-0">
                                                {{ step.display_message }}
                                            </li>
                                        </ng-container>
                                    </ol>
                                </div>
                            </div>
                        </ng-template>

                        <button *ngIf="assistantChatService.citationsByRunId(message.run_id)[0]"
                            [menuTriggerFor]="citationsList"
                            class="group size-7 grid place-content-center rounded text-slate-500 hover:text-slate-700 opacity-80 group-hover:opacity-100"
                            ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }"
                            [ngxTippy]="'Fuentes Consultadas'">
                            <i class="fa-duotone fa-solid fa-quote-left opacity-80 group-hover:opacity-100"
                                style="font-size: 0.9rem"></i>
                        </button>
                        <ng-template #citationsList>
                            <div class="uisr-dropdown-menu max-w-96" cdkMenu>
                                <div class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
                                    <div class="font-medium text-slate-800">
                                        Fuentes Consultadas
                                    </div>
                                </div>
                                <div class="prose px-3 p-1">
                                    <ul class="text-xs"
                                        *ngIf="assistantChatService.citationsByRunId(message.run_id)[0]">
                                        <ng-container
                                            *ngFor="let citation of assistantChatService.citationsByRunId(message.run_id)">
                                            <li [ngSwitch]="citation.type">
                                                <!-- Información que viene desde el notion del centro de ayuda -->
                                                <div *ngSwitchCase="'ayudante_tutoriales'">
                                                    <i class="fa-duotone fa-arrow-up-right-from-square opacity-75"></i>
                                                    Centro de Ayuda
                                                    <br>
                                                    Artículo:
                                                    <a [href]="citation.sourceUrl" target="_blank">{{ citation.name
                                                        }}</a>
                                                </div>

                                                <!-- Fragmentos de documentos adjuntos -->
                                                <div *ngSwitchCase="'fragmentos'">
                                                    <i class="fa-duotone fa-file-lines opacity-75"></i> Documento:
                                                    <a>{{ citation.name }}</a>
                                                    <br>
                                                    <span *ngIf="citation.pages" class="flex items-center gap-2">
                                                        <span>Páginas: </span>
                                                        <a *ngFor="let page of citation.pages.slice(0, 5)">{{ page
                                                            }}</a>
                                                        <span *ngIf="citation.pages.length > 5"
                                                            [ngxTippy]="'Total páginas: ' + citation.pages.length">
                                                            +{{ citation.pages.length - 5 }} más
                                                        </span>
                                                    </span>
                                                </div>

                                                <!-- Documento adjunto completo -->
                                                <div *ngSwitchCase="'documento_completo'">
                                                    <i class="fa-duotone fa-file-lines opacity-75"></i> Documento:
                                                    <a>{{ citation.name }}</a>
                                                    <br>
                                                    <span *ngIf="citation.pages" class="flex items-center gap-2">
                                                        <span>Páginas: </span>
                                                        <a *ngFor="let page of citation.pages.slice(0, 5)">{{ page
                                                            }}</a>
                                                        <span *ngIf="citation.pages.length > 5"
                                                            [ngxTippy]="'Total páginas: ' + citation.pages.length">
                                                            +{{ citation.pages.length - 5 }} más
                                                        </span>
                                                    </span>
                                                </div>

                                                <!-- Asuntos -->
                                                <div *ngSwitchCase="'asuntos'">
                                                    <i class="fa-duotone fa-folder opacity-75"></i> Asuntos:
                                                    <br>
                                                    <span *ngIf="citation.asuntos?.length"
                                                        class="flex items-center gap-2">
                                                        <a *ngFor="let asunto of citation.asuntos.slice(0, 3)">
                                                            {{ asunto.numeroAsunto }}
                                                        </a>
                                                        <span *ngIf="citation.asuntos.length > 3"
                                                            [ngxTippy]="'Total asuntos: ' + citation.asunto.length">
                                                            +{{ citation.pages.length - 3 }} más
                                                        </span>
                                                    </span>
                                                </div>

                                                <div *ngSwitchDefault>
                                                    Tipo desconocido
                                                </div>
                                            </li>

                                        </ng-container>
                                    </ul>
                                </div>

                            </div>
                        </ng-template>

                        <!-- Botones de feedback -->
                        <button [disabled]="(sendingFeedback | async) || message.feedback"
                            *ngIf="message.feedback != -1 && !message?.typedId" ngxTippy="Buena Respuesta"
                            (click)="sendFeedback(message, 1)"
                            class="group size-7 grid place-content-center rounded text-slate-500 hover:active:text-slate-700 opacity-80 group-hover:opacity-100">
                            <i class="fa-thumbs-up" style="font-size: 0.9rem"
                                [ngClass]="message.feedback == 1 ? 'fa-solid' : 'fa-regular'"></i>
                        </button>
                        <button [disabled]="(sendingFeedback | async) || message.feedback"
                            *ngIf="message.feedback != 1 && !message?.typedId" ngxTippy="Mala Respuesta"
                            (click)="sendFeedback(message, -1)"
                            class="group size-7 grid place-content-center rounded text-slate-500 hover:active:text-slate-600 opacity-80 group-hover:opacity-100">
                            <i class="fa-thumbs-down" style="font-size: 0.9rem"
                                [ngClass]="message.feedback == -1 ? 'fa-solid' : 'fa-regular'"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- User -->
            <div *ngIf="message.role == 'user'" class="flex items-start mb-4 last:mb-0 justify-end"
                style="overflow-anchor: none;">
                <div class="group/message">
                    <div [innerHTML]="message.content"
                        class="prose text-sm bg-indigo-500 text-white p-3 rounded-lg rounded-tr-none border border-transparent mb-1">
                    </div>
                    <div class="group/actions flex invisible group-hover/message:visible parent-div">
                        <button *ngIf="!message?.typedId" ngxTippy="Copiar" (click)="shareMessage(message.content)"
                            class="group size-7 grid place-content-center rounded text-slate-500 hover:text-slate-700 opacity-80 group-hover:opacity-100">
                            <i class="fa-solid fa-copy" style="font-size: 0.9rem"></i>
                        </button>
                    </div>
                </div>
                <div class="flex items-center ml-4 gap-4">
                    <img class="rounded-full min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]"
                        [src]="profilePicture" />
                    <i *ngIf="message.error" (click)="assistantChatService.retryMessage(message.id)" ngxTippy
                        [ngxTippy]="'Ha ocurrido un error al enviar el mensaje.<br>Click aquí para intentar nuevamente.'"
                        [tippyProps]="{ placement: 'top', allowHTML: true, hideOnClick: true }"
                        class="fa-duotone fa-circle-exclamation cursor-pointer" style="font-size: 1.3rem"></i>
                </div>
            </div>

        </ng-container>
    </ng-container>

    <!-- Assistant processing -->
    <div *ngIf="assistantChatService.loadingStates.sendingMessage.value || assistantChatService.loadingStates.activeRun.value"
        class="flex items-start mb-4 last:mb-0" style="overflow-anchor: none;">
        <img class="rounded-full mr-4 min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]"
            src="/assets/images/amparo-isotipo.webp" />
        <div>
            <div class="bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-sm">
                <div class="prose text-sm">
                    <div class="flex items-center">
                        <div>
                            <p style="margin: 0; display: inline-block; width: 0px;">&nbsp;</p>
                            <svg class="fill-current text-slate-400 inline-block" viewBox="0 0 15 3" width="15"
                                height="3">
                                <circle cx="1.5" cy="1.5" r="1.5">
                                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                        begin="0.1"></animate>
                                </circle>
                                <circle cx="7.5" cy="1.5" r="1.5">
                                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                        begin="0.2"></animate>
                                </circle>
                                <circle cx="13.5" cy="1.5" r="1.5">
                                    <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                        begin="0.3"></animate>
                                </circle>
                            </svg>
                        </div>
                        <div *ngIf="assistantChatService.runSteps[0]" class="text-xs ml-3">
                            <p>
                                {{ assistantChatService.runSteps[assistantChatService.runSteps.length -
                                1].display_message }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="height: 1px; overflow-anchor: auto;"></div>
</div>

<div *ngIf="assistantChatService.loadingStates.loadingThread.value"
    class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-slate-400" disabled>
    <svg class="animate-spin w-8 h-8 fill-current shrink-0" viewBox="0 0 16 16">
        <path
            d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z">
        </path>
    </svg>
</div>